import React from 'react';
import Layout from '../components/global/layout';
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import Button from '../components/mui/button';
import PageBanner from '../components/layouts/page-banner';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';
import HowItWorksProcess from '../components/layouts/how-it-works-process';
import HowItWorksFeatures from '../components/layouts/how-it-works-features';
import CallToAction from '../components/layouts/call-to-action';
import SEO from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core/styles';

const useHowItWorksStyles = makeStyles((theme) => ({
  meetMichelle: {
    margin: 'auto',
    marginTop: '0 !important',
    maxWidth: 1380,
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
      marginBottom: 60,
      '& .gatsby-image-wrapper': {
        marginBottom: 30,
      },
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      marginBottom: 120,
    },
  },
}));

const HowItWorksPage: React.FC = () => {
  const howItWorksStyles = useHowItWorksStyles();

  return (
    <Layout>
      <SEO
        title="Develop, Launch, Manage, and Iterate Websites Faster"
        description="Turn your website into a marketing tool that drives business growth using our web operations and services."
      />
      <PageBanner
        maxWidth={1100}
        heading="Make Your Website a Marketing Tool That Drives Business Growth"
        imageSrc="how-it-works-banner.jpg"
      />
      <LightMode>
        <VerticalPadding lg>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={12} lg={5}>
                <Typography variant="h2" color="textSecondary">
                  Operate at the Speed of Marketing
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1" component="p" className="lead">
                  Whether it&apos;s supporting an existing marketing and web
                  team or integrating directly with marketing to be the web
                  team, our processes are designed to integrate with yours in a
                  way that specifically matches your needs.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </LightMode>
      <Box bgcolor="primary.main">
        <VerticalPadding>
          <Container>
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                md={11}
                lg={10}
                xl={9}
                style={{ textAlign: 'center' }}
              >
                <Typography
                  variant="h3"
                  style={{
                    color: 'white',
                    fontFamily: 'Visby CF',
                    textTransform: 'unset',
                  }}
                >
                  With Marvel <span style={{ fontSize: '0.8em' }}>&amp;</span>{' '}
                  Snap, you get one point of contact for web development,
                  hosting, and ongoing management. You can scale web operations
                  both up and down to adapt to changing marketing requirements.
                  And you can easily roll out new digital experiences and assets
                  as needed.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </Box>
      <VerticalPadding>
        <Container>
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              md={11}
              lg={10}
              xl={9}
              style={{ textAlign: 'center' }}
            >
              <Typography
                variant="h2"
                style={{
                  color: 'white',
                  fontFamily: 'Visby CF',
                  marginBottom: '2em',
                }}
              >
                A Typical Month With a Marvel{' '}
                <span style={{ fontSize: '0.8em' }}>&amp;</span> Snap Web
                Operations Package
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                style={{ color: 'white' }}
              >
                Every month, we enable more efficient, ongoing collaboration
                between marketing and web operations to accelerate development,
                reduce downtime, and eliminate the worries of ongoing
                management.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </VerticalPadding>
      <Paper>
        <VerticalPadding>
          <Container>
            <Grid
              container
              className={howItWorksStyles.meetMichelle}
              justify="space-between"
            >
              <Grid item xs={12} sm={4}>
                <StaticImage
                  src="../images/how-it-works-michelle.jpg"
                  objectFit="cover"
                  alt=""
                />
              </Grid>
              <Grid item xs={12} sm={7}>
                <Typography variant="h2" color="primary">
                  Meet Michelle
                </Typography>
                <p>Like you, she is a busy marketing manager.</p>
                <p>
                  She is constantly adding more things to her team’s to-do list
                  for the website. But her team is small and has a million other
                  things on the go.
                </p>
                <p>
                  <strong style={{ color: 'white' }}>
                    Luckily, she found Marvel &amp; Snap!
                  </strong>{' '}
                  A web operations team that can help her meet the aggressive
                  marketing goals she has set out for her team.
                </p>
              </Grid>
            </Grid>
          </Container>
          <HowItWorksProcess />
        </VerticalPadding>
      </Paper>
      <HowItWorksFeatures />
      <CallToAction imageSrc="call-to-action-laptop-chair.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '9em' }}
        >
          Get worry-free web operations
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Develop, launch, manage, and iterate digital marketing assets faster.
          And bring advanced digital experiences to your audience more quickly
          and efficiently.
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          to="/contact/"
        >
          Let&apos;s talk
        </Button>
      </CallToAction>
    </Layout>
  );
};

export default HowItWorksPage;
