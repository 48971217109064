import React from 'react';

import { makeStyles } from '@material-ui/styles';

const useIconStyles = makeStyles({
  root: {
    height: 'auto',
    width: 'auto',
  },
});

interface IconProps {
  className?: string;
  title?: string;
  stroke?: string;
}

const DevelopmentIcon: React.FC<IconProps> = (props) => {
  const iconStyles = useIconStyles();

  return (
    <svg
      className={`${iconStyles.root} ${props.className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="61"
      viewBox="0 0 65 61"
    >
      <title>{props.title}</title>
      <g transform="translate(-848.044 -2747.428)">
        <g>
          <path
            d="M858.544,2807.928h9v-8h-9a2,2,0,0,1-2-2v-24h48v18h8v-34a10,10,0,0,0-10-10h-44a10,10,0,0,0-10,10v40A10,10,0,0,0,858.544,2807.928Zm46-50v8h-30v-10h28A2,2,0,0,1,904.544,2757.928Zm-46-2h8v10h-10v-8A2,2,0,0,1,858.544,2755.928Z"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
          <path
            d="M880.544,2807.588l10.83-10.83a4,4,0,0,0,0-5.657l0,0-10.83-10.83-5.66,5.66,8,8-8,8Z"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
        </g>
        <path
          d="M904.544,2807.928h8v-8h-8Z"
          transform="translate(0 -1)"
          fill="none"
          stroke={props.stroke}
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};

DevelopmentIcon.defaultProps = {
  title: 'Development icon',
  stroke: '#9998a5',
};

export default DevelopmentIcon;
