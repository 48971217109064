import React from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { DevelopmentIcon, HostingIcon, ManagementIcon } from '../svg/icons';

const features = [
  {
    icon: <DevelopmentIcon stroke="white" />,
    subheading: 'Built by',
    heading: 'Expert, Cross-Platform Developers',
    description: (
      <div>
        <p style={{ fontWeight: 500 }}>
          Our team includes build, launch, and development experts for
          everything from one-page sites, campaign-specific landing pages, and
          complex multi-page websites to web applications that support the most
          demanding and dynamic digital marketing strategies.
        </p>
        <p style={{ fontWeight: 500 }}>
          Whether it&apos;s a WordPress, Shopify, or custom website, our
          development team ensures your website is built with scalability and
          flexibility in mind so it can meet your marketing needs today and
          tomorrow.
        </p>
      </div>
    ),
  },
  {
    icon: <HostingIcon stroke="white" />,
    subheading: 'Powered by',
    heading: 'an Industry-Leading Hosting Platform',
    description: (
      <div>
        <p>
          Our high-performance hosting services are built on Pantheon&apos;s
          best-in-class, enterprise-grade, secure infrastructure that boasts
          load times as low as one-tenth of a second and 99.99% uptime.
        </p>
        <p>
          As a Pantheon partner, we leverage this reliability to provide hosting
          solutions structured to meet your unique requirements.
        </p>
        <p>
          Pantheon&apos;s trusted and proven hosting platform eliminates the
          need for ongoing research, assessment, procurement, purchasing, and
          cost management, so you never have to worry about hosting again.
        </p>
      </div>
    ),
  },
  {
    icon: <ManagementIcon stroke="white" />,
    subheading: 'Backed by',
    heading: 'Proactive Management',
    description: (
      <div>
        <p style={{ fontWeight: 500 }}>
          Our proactive web management services enable highly agile, ongoing,
          iterative development, enhancement, and optimization that ensure all
          your digital assets are running smoothly, and that optimal performance
          is maintained at all times.
        </p>
        <p style={{ fontWeight: 500 }}>
          More responsive, iterative development makes it easier for you to
          create and enhance digital marketing experiences with everything from
          minor design and content adjustments to new pages, targeted
          microsites, or complete overhauls.
        </p>
      </div>
    ),
  },
];

const useFeatureStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    '& [class*="MuiGrid-container"]': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  middle: {
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.only('md')]: {
      position: 'relative',
      '&::before': {
        backgroundColor: theme.palette.primary.dark,
        content: '""',
        display: 'block',
        height: '100%',
        left: '50%',
        position: 'absolute',
        top: 0,
        transform: 'translateX(-50%)',
        width: '100vw',
        zIndex: 1,
      },
    },
  },
  sizer: {
    margin: 'auto',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 400,
    },
  },
  icon: {
    '& svg': {
      display: 'block',
      height: 64,
      width: 'auto',
    },
  },
  heading: {
    color: 'white',
    fontFamily: 'Visby CF',
    margin: '2rem 0',
    maxWidth: 370,
    '& small': {
      fontSize: '.75em',
    },
  },
  description: {
    color: 'white',
    '& p': {
      margin: '2rem auto',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

const HowItWorksFeatures: React.FC = () => {
  const featureStyles = useFeatureStyles();

  return (
    <Box className={featureStyles.root}>
      <Container>
        <Grid container spacing={10} alignItems="flex-start" justify="center">
          {features.map((feature, index) => (
            <Grid
              item
              className={index === 1 ? featureStyles.middle : undefined}
              key={index}
              xs={12}
              lg={4}
            >
              <Box py={3}>
                <div className={featureStyles.sizer}>
                  <div className={featureStyles.icon}>{feature.icon}</div>
                  <Typography
                    className={featureStyles.heading}
                    variant="h3"
                    color="textSecondary"
                  >
                    <small>{feature.subheading}</small>
                    <br />
                    {feature.heading}
                  </Typography>
                  <Typography
                    className={featureStyles.description}
                    variant="body2"
                    component="div"
                  >
                    {feature.description}
                  </Typography>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorksFeatures;
