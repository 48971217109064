import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Container, Typography } from '@material-ui/core';

import { Waypoint } from 'react-waypoint';
import { useCountUp } from 'react-countup';

const timeStarting = 20;

const process = [
  {
    date: 'June 1',
    time: '8:05 a.m.',
    description: (
      <p>
        To kick off a busy month, Michelle sends an email to her team and Marvel
        &amp; Snap Support to set up priorities for a new product launch that
        will take place in a few weeks. She assigns Sarah to be the project
        lead.
      </p>
    ),
    timeRemaining: timeStarting,
  },
  {
    date: 'June 1',
    time: '9:35 a.m.',
    description: (
      <p>
        Marvel &amp; Snap responds to Michelle&apos;s email to let everyone know
        that a card has been created on Trello, the collaborative dashboard, to
        track the different tasks and due dates for a successful launch. All
        team members are invited to collaborate. Marvel &amp; Snap has also
        created a new Slack channel to allow for quicker conversations.
      </p>
    ),
    timeRemaining: 19.5,
  },
  {
    date: 'June 5',
    time: '2:00 p.m.',
    description: (
      <p>
        As the week progresses, Sarah creates a variety of tasks in Trello and
        sets priorities, while Marvel &amp; Snap assigns each task to members of
        the team.
      </p>
    ),
    timeRemaining: 19.25,
  },
  {
    date: 'June 8',
    time: '10:30 a.m.',
    description: (
      <p>
        A team meeting kicks off the week. Meanwhile, Marvel &amp; Snap creates
        a new development environment, develops new landing and product pages,
        updates banners across the site, and creates a new email campaign.
      </p>
    ),
    timeRemaining: 18.75,
  },
  {
    date: 'June 9',
    time: '12:30 p.m.',
    description: (
      <div>
        <p>
          Sarah receives an email from Marvel &amp; Snap with the first draft of
          the email campaign attached.
        </p>
        <p>
          She reviews it and makes a few content updates. She quickly sends a
          Slack message to Marvel &amp; Snap with the changes. Within a few
          minutes, those changes have been made and a new email arrives in
          Sarah&apos;s inbox for review.
        </p>
        <p>Everything looks good. The email is ready to go!</p>
      </div>
    ),
    timeRemaining: 15,
  },
  {
    date: 'June 11',
    time: '9:45 a.m.',
    description: (
      <div>
        <p>
          The Marvel &amp; Snap team puts the finishing touches on the site
          updates. As part of its quality assurance process, it scans the new
          pages for page speed performance, mobile usability issues, and
          accessibility compliance (WCAG).
        </p>
        <p>
          Marvel &amp; Snap messages the team through Slack with links to the
          new development environment where the changes can be approved before
          pushing them live.
        </p>
        <p>Sarah circulates the site internally for review.</p>
        <p>
          Michelle makes a few content updates on one page and design updates on
          another. Her team can easily log in and make the content changes
          directly and tasks Marvel &amp; Snap with the design updates.
        </p>
      </div>
    ),
    timeRemaining: 6,
  },
  {
    date: 'June 17',
    time: '3:30 p.m.',
    description: (
      <p>
        After a few more rounds of changes, Michelle gives a thumbs-up! She asks
        Sarah if Marvel &amp; Snap can launch the new landing page and send the
        email campaign on Monday at 9:00 a.m. to coincide with the news release
        and the start of a trade show the company is attending.
      </p>
    ),
    timeRemaining: 3,
  },
  {
    date: 'June 22',
    time: '9:00 a.m.',
    description: (
      <div>
        <p>
          Marvel &amp; Snap takes a backup of the live site and starts
          deployment. Within minutes, the site changes are live, and the team is
          notified through Slack.
        </p>
        <p>Sarah hits send on the email campaign.</p>
        <p>
          Marvel &amp; Snap monitors the site for any issues. Everything looks
          great!
        </p>
      </div>
    ),
    timeRemaining: 2,
  },
  {
    date: 'June 29',
    time: '9:00 a.m.',
    description: (
      <div>
        <p>
          As part of Marvel &amp; Snap&apos;s service, Michelle and Sarah
          receive their weekly analytics snapshot. They are quickly able to see
          the increase in traffic from the product launch, as well as the
          performance metrics on their email campaign and social media.
        </p>
        <p>It was a success!</p>
      </div>
    ),
    timeRemaining: 2,
  },
];

const stepTransition = '0.3s ease-out';

const useProcessStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    maxWidth: 1380,
    position: 'relative',
    '&::after': {
      clear: 'both',
      content: '""',
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 13,
    },
    '&--track': {
      '&::before': {
        backgroundColor: 'white',
        content: '""',
        display: 'block',
        height: '100%',
        opacity: 0.15,
        position: 'absolute',
        top: 15,
        width: 1,
      },
      [theme.breakpoints.down('md')]: {
        '&::before': {
          left: 0,
        },
      },
      [theme.breakpoints.up('lg')]: {
        '&::before': {
          left: '50%',
        },
      },
    },
  },
  step: {
    position: 'relative',
    '&::before': {
      backgroundColor: '#8E8E9A',
      content: '""',
      height: 11,
      position: 'absolute',
      top: 15,
      transition: `all ${stepTransition}`,
      width: 11,
    },
    '&__content': {
      opacity: 0.3,
      transition: `opacity ${stepTransition}`,
    },
    '&__heading': {
      position: 'relative',
      transition: `color ${stepTransition}`,
      '&::before': {
        backgroundColor: 'white',
        content: '""',
        display: 'block',
        height: 1,
        opacity: 0.15,
        position: 'absolute',
        top: '1.25rem',
        width: 50,
      },
    },
    '&--active': {
      opacity: 1,
    },
    '&--active::before': {
      backgroundColor: theme.palette.primary.main,
      height: 26,
      top: 8,
      width: 26,
    },
    '&--active &__content': {
      opacity: 1,
    },
    '&--active &__heading': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 110,
      '&::before': {
        left: 0,
        transform: 'translateX(-50%)',
      },
      '&__heading': {
        '&::before': {
          left: -75,
        },
      },
      '&:not(&--final)': {
        paddingBottom: 110,
      },
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 70,
      '&__heading': {
        '&::before': {
          left: -45,
          width: '30px !important',
        },
      },
      '&:not(&--final)': {
        paddingBottom: 70,
      },
    },
    [theme.breakpoints.up('lg')]: {
      clear: 'both',
      height: 380,
      overflow: 'visible',
      width: '50%',
      '&:nth-child(odd):not(&--final)': {
        float: 'left',
        paddingRight: 130,
        textAlign: 'right',
        '&::before': {
          right: 0,
          transform: 'translateX(50%)',
        },
      },
      '&:nth-child(odd):not(&--final) &__heading': {
        '&::before': {
          right: -85,
        },
      },
      '&:nth-child(even), &--final': {
        float: 'right',
        paddingLeft: 130,
        '&::before': {
          left: 0,
          transform: 'translateX(-50%)',
        },
      },
      '&--final': {
        height: 'auto',
      },
      '&:nth-child(even) &__heading, &--final &__heading': {
        '&::before': {
          left: -85,
        },
      },
    },
  },
  timeRemaining: {
    color: 'inherit',
    fontSize: 20,
    '&__value': {
      display: 'inline-block',
    },
  },
  rollingHours: {
    animation: '$rollingHours 1200ms ease-out',
    animationDelay: '300ms',
    animationFillMode: 'both',
  },
  '@keyframes rollingHours': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-70px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
}));

const countDecimals = (number: number) => {
  const numberString = number.toString();
  const decimalIndex = numberString.indexOf('.');
  return decimalIndex === -1 ? 0 : numberString.length - decimalIndex - 1;
};

interface ProcessStepProps {
  step: {
    date: string;
    time: string;
    description: React.ReactNode;
    timeRemaining?: number;
  };
  previousTimeStarting?: number;
  className?: string;
}

const ProcessStep: React.FC<ProcessStepProps> = ({
  step,
  previousTimeStarting,
  className,
}) => {
  const [active, setActive] = useState(false);
  const [hasEntered, setHasEntered] = useState(false);

  const processStyles = useProcessStyles();

  let stepClasses = processStyles.step;

  if (active) stepClasses += ` ${processStyles.step}--active`;

  if (className) stepClasses += ` ${className}`;

  const { countUp, start: startCountUp } = useCountUp({
    start: step.timeRemaining ? previousTimeStarting ?? timeStarting : 2,
    end: step.timeRemaining ?? timeStarting,
    decimals: countDecimals(step.timeRemaining ?? timeStarting),
    startOnMount: false,
    duration: 1.5,
    useEasing: false,
  });

  const rollingHoursAttributes = {};

  if (hasEntered) {
    rollingHoursAttributes['className'] = processStyles.rollingHours;
  } else {
    rollingHoursAttributes['style'] = { visibility: 'hidden' };
  }

  const timeRemaining = (
    <span>
      Time remaining{!step.timeRemaining && ' in July'}:&nbsp;
      <span className={`${processStyles.timeRemaining}__value`}>
        {countUp}
        &nbsp;hours
      </span>
      {!step.timeRemaining && (
        <div {...rollingHoursAttributes}>+ 2 hours from June</div>
      )}
    </span>
  );

  const onEnter = () => {
    setActive(true);
    if (!hasEntered) {
      startCountUp();
      setHasEntered(true);
    }
  };

  const onLeave = () => {
    setActive(false);
  };

  return (
    <Waypoint
      onEnter={onEnter}
      onLeave={onLeave}
      topOffset="40%"
      bottomOffset="40%"
    >
      <div className={stepClasses}>
        <div className={`${processStyles.step}__content`}>
          <Typography className={`${processStyles.step}__heading`} variant="h3">
            {step.date}&nbsp;&ndash;&nbsp;{step.time}
          </Typography>
          {step.description}
          <Typography className={processStyles.timeRemaining} variant="h3">
            {timeRemaining}
          </Typography>
        </div>
      </div>
    </Waypoint>
  );
};

const HowItWorksProcess: React.FC = () => {
  const processStyles = useProcessStyles();

  let previousTimeStarting = timeStarting;

  return (
    <Container>
      <div
        className={`${processStyles.container} ${processStyles.container}--track`}
      >
        {process.map((step, index) => {
          const element = (
            <ProcessStep
              key={index}
              step={step}
              previousTimeStarting={previousTimeStarting}
            />
          );

          previousTimeStarting = step.timeRemaining;

          return element;
        })}
      </div>
      <div className={processStyles.container}>
        <ProcessStep
          className={`${processStyles.step}--final`}
          step={{
            date: 'July 1',
            time: '9:00 a.m.',
            description: (
              <div>
                <p>
                  Michelle receives a detailed timesheet from Marvel &amp; Snap
                  that shows where hours were applied on June&apos;s activities.
                </p>
                <p>
                  Not all of Michelle&apos;s hours with Marvel &amp; Snap were
                  used, but Michelle is happy to see those hours get rolled over
                  to the next month.
                </p>
              </div>
            ),
          }}
        />
      </div>
    </Container>
  );
};

export default HowItWorksProcess;
