import React from 'react';

import { makeStyles } from '@material-ui/styles';

import uuid from 'react-uuid';

const useIconStyles = makeStyles({
  root: {
    height: 'auto',
    width: 'auto',
  },
});

interface IconProps {
  className?: string;
  title?: string;
  stroke?: string;
}

const ManagementIcon: React.FC<IconProps> = (props) => {
  const iconStyles = useIconStyles();

  const clipPathId = uuid();

  return (
    <svg
      className={`${iconStyles.root} ${props.className}`}
      width="70"
      height="70"
      viewBox="0 0 70 70"
    >
      <title>{props.title}</title>
      <defs>
        <clipPath id={clipPathId}>
          <rect
            width="70"
            height="70"
            transform="translate(-3 -3)"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g transform="translate(3 3)">
        <g clipPath={`url(#${clipPathId})`}>
          <path
            d="M8,8h8V0H8A8,8,0,0,0,0,8v8H8Z"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
          <path
            d="M64,8a8,8,0,0,0-8-8H48V8h8v8h8Z"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
          <path
            d="M64,48H56v8H48v8h8a8,8,0,0,0,8-8Z"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
          <path
            d="M8,64h8V56H8V48H0v8a8,8,0,0,0,8,8"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
          <path
            d="M50.385,23.3l-5.657-5.657-16.97,16.97-8.485-8.485-5.658,5.656L22.1,40.276l5.657,5.656,5.656-5.656Z"
            fill="none"
            stroke={props.stroke}
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

ManagementIcon.defaultProps = {
  title: 'Management icon',
  stroke: '#9998a5',
};

export default ManagementIcon;
