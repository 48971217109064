import React from 'react';

import { makeStyles } from '@material-ui/styles';

const useIconStyles = makeStyles({
  root: {
    height: 'auto',
    width: 'auto',
  },
});

interface IconProps {
  className?: string;
  title?: string;
  stroke?: string;
}

const HostingIcon: React.FC<IconProps> = (props) => {
  const iconStyles = useIconStyles();

  return (
    <svg
      className={`${iconStyles.root} ${props.className}`}
      xmlns="http://www.w3.org/2000/svg"
      width="62.2"
      height="65"
      viewBox="0 0 62.2 65"
    >
      <title>{props.title}</title>
      <g transform="translate(-1065.683 -2665.331)">
        <path
          d="M1124.3,2685.8c4.4-4.9,4-12.5-0.9-16.9c-2.2-2-5-3-8-3.1h-32c-11,1.7-18.6,12-16.9,23.1
          c1.3,8.7,8.2,15.6,16.9,16.9h12v16h-24v8h40v-8h-8v-16h12c6.6,0,12-5.4,12-12C1127.3,2690.9,1126.2,2688,1124.3,2685.8z
          M1115.3,2697.8h-32c-2.2,0-4-1.8-4-4s1.8-4,4-4h32c2.2,0,4,1.8,4,4S1117.5,2697.8,1115.3,2697.8z M1115.3,2681.8h-32
          c-2.2,0-4-1.8-4-4s1.8-4,4-4h32c2.2,0,4,1.8,4,4S1117.5,2681.8,1115.3,2681.8z"
          fill="none"
          stroke={props.stroke}
        />
        <path
          d="M1119.3,2729.8h8v-8h-8V2729.8z"
          fill="none"
          stroke={props.stroke}
        />
      </g>
    </svg>
  );
};

HostingIcon.defaultProps = {
  title: 'Hosting icon',
  stroke: '#9998a5',
};

export default HostingIcon;
